var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-row"},[_c('div',{staticClass:"head-title"},[_vm._v("手机号登录")]),_c('div',{staticClass:"head-label"},[_vm._v("未注册的手机号验证后将自动注册")]),_c('van-form',{ref:"loginForm",attrs:{"validate-first":""},on:{"submit":_vm.onSubmit}},[_c('van-field',{staticClass:"login-input",attrs:{"border":false,"name":"mobile","type":"number","maxlength":"11","disabled":_vm.mobileDisabled,"clearable":"","placeholder":"请输入手机号","rules":[
				{ required: true, message: '手机号不能为空' },
				{
					pattern: /^1[3456789]\d{9}$/,
					message: '请输入正确手机号',
				},
			]},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}}),_c('van-field',{staticClass:"login-input",attrs:{"border":false,"clearable":"","type":"number","placeholder":"请输入验证码","maxlength":"6","rules":[{ required: true, message: '验证码不能为空' }]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{staticClass:"sms-button",attrs:{"loading":_vm.smsLoading,"loading-text":"发送中...","native-type":"button","disabled":_vm.disabled,"size":"small","plain":""},on:{"click":_vm.sendSms}},[_vm._v(_vm._s(_vm.disabled ? _vm.time : "获取验证码"))])]},proxy:true}]),model:{value:(_vm.form.smsCode),callback:function ($$v) {_vm.$set(_vm.form, "smsCode", $$v)},expression:"form.smsCode"}}),_c('van-checkbox',{staticStyle:{"width":"94vw","margin":"8vw 0 4vw 0","align-items":"baseline"},attrs:{"checked-color":"#F4CD13","icon-size":"16","shape":"square"},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_c('div',{staticClass:"checked-style"},[_vm._v(" 我已阅读并同意 "),_c('span',{staticClass:"base-color",on:{"click":function($event){return _vm.goAgreement('PATIENT_EMPLOY')}}},[_vm._v("《用户服务协议》")]),_vm._v("和 "),_c('span',{staticClass:"base-color",on:{"click":function($event){return _vm.goAgreement('PATIENT_PRIVACY')}}},[_vm._v("《用户隐私政策》")])])]),_c('van-button',{attrs:{"color":"#F4CD13","block":"","native-type":"submit"}},[_vm._v("登录/注册")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }