<template>
	<div class="login-row">
		<div class="head-title">手机号登录</div>
		<div class="head-label">未注册的手机号验证后将自动注册</div>
		<van-form ref="loginForm" validate-first @submit="onSubmit">
			<van-field
				:border="false"
				class="login-input"
				name="mobile"
				v-model="form.mobile"
				type="number"
				maxlength="11"
				:disabled="mobileDisabled"
				clearable
				placeholder="请输入手机号"
				:rules="[
					{ required: true, message: '手机号不能为空' },
					{
						pattern: /^1[3456789]\d{9}$/,
						message: '请输入正确手机号',
					},
				]"
			/>
			<van-field
				:border="false"
				clearable
				class="login-input"
				v-model="form.smsCode"
				type="number"
				placeholder="请输入验证码"
				maxlength="6"
				:rules="[{ required: true, message: '验证码不能为空' }]"
			>
				<template #button>
					<van-button
						:loading="smsLoading"
						loading-text="发送中..."
						native-type="button"
						class="sms-button"
						:disabled="disabled"
						size="small"
						plain
						@click="sendSms"
						>{{ disabled ? time : "获取验证码" }}</van-button
					>
				</template>
			</van-field>
			<van-checkbox
				style="width: 94vw; margin: 8vw 0 4vw 0; align-items: baseline"
				checked-color="#F4CD13"
				icon-size="16"
				v-model="checked"
				shape="square"
			>
				<div class="checked-style">
					我已阅读并同意
					<span
						class="base-color"
						@click="goAgreement('PATIENT_EMPLOY')"
						>《用户服务协议》</span
					>和
					<span
						class="base-color"
						@click="goAgreement('PATIENT_PRIVACY')"
						>《用户隐私政策》</span
					>
				</div>
			</van-checkbox>
			<van-button color="#F4CD13" block native-type="submit"
				>登录/注册</van-button
			>
		</van-form>
	</div>
</template>

<script>
import { Toast } from "vant";
export default {
	metaInfo() {
		return {
			title: "",
		};
	},
	data() {
		return {
			checked: false,
			smsLoading: false,
			disabled: false,
			mobileDisabled: false,
			time: "",
			timeNumber: null,
			form: {
				mobile: "",
				smsCode: "",
			},
      userInfo: {}
		};
	},
	computed: {},
	created() {},
	methods: {
		onSubmit() {
			if (!this.checked) {
				Toast("请阅读并勾选页面协议");
				return;
			}

      this.loginSubmit()
		},

		// 登录提交
		loginSubmit() {
      const params = {
        role: 1,
        vcode: this.form.smsCode,
        loginChannel: 0,
        mobile: this.form.mobile,
      }

      this.$api.user.login(params).then(({ code, data, msg }) => {
        if (code === 0) {
          this.$store.commit("user/SET_TOKEN", data.token);
          this.getUserInfo()
        }
      })
    },

    // 获取用户信息
    getUserInfo() {
      this.$api.user.getUserInfo({ id: this.userInfo.id }).then(({ code, msg, data }) => {
        if (code === 0) {
          this.userInfo = data
          console.log(this.userInfo)
          if (this.userInfo.idcardStatus === 1) {
            this.authThree()
          } else {
            Toast('请去医阵营小程序进行实名认证')
          }
        }
      })
    },

    // 授权三方
    authThree() {
      this.$api.user.getAuthCode(this.userInfo.id).then(({ code, msg, data }) => {
        if (code === 0) {
          location.href = `https://medi.huijinchain.com/#/login?code=${data}&state=MDWK`
        }
      })
    },

		//发送验证码
		sendSms() {
			this.$refs.loginForm
				.validate("mobile")
				.then((valid) => {
					this.smsLoading = true;
          this.loginCode();
				})
				.catch((err) => {
					this.smsLoading = false;
					this.clearTimer();
				});
		},

		// 登录验证码
		loginCode() {
      const params = {
        role: 1,
        mobile: this.form.mobile,
        loginPlatform: 2
      }
			this.$api.user
				.getSmsCode(params)
				.then(({ data }) => {
					Toast("验证码发送成功");
					this.smsLoading = false;
					this.startTimer();
				})
				.catch((err) => {
					this.smsLoading = false;
					this.clearTimer();
				});
		},

		//开始计时
		startTimer() {
			let time = 60;
			let _this = this;
			let timeNumber = setInterval(() => {
				time--;
				let disabled = true;
				if (time <= 0) {
					disabled = false;
					_this.clearTimer();
				}
				this.disabled = disabled;
				this.time = time + "s";
			}, 1000);
			this.disabled = true;
			this.time = time + "s";
			this.timeNumber = timeNumber;
		},

    // 清除计时器
		clearTimer() {
			if (this.timeNumber) {
				clearInterval(this.timeNumber);
			}
		},
		goAgreement(type) {
			this.$router.push({
				path: `/agreement/detail/${type}`,
			});
		},
	},
};
</script>

<style scoped>
.login-row {
	min-height: 100vh;
	background-color: #ffffff;
	padding: 0 8vw;
}
.head-title {
	font-size: 6.4vw;
	font-weight: bold;
	color: #000000;
	line-height: 8.9vw;
	padding-top: 5.8vw;
}
.head-label {
	font-size: 3.4vw;
	color: #999999;
	margin-top: 1vw;
	line-height: 4.9vw;
	margin-bottom: 7.4vw;
}
.login-input {
	padding: 0;
	width: 84vw;
	border-bottom: 1px solid #e6e8ed;
	padding: 3.2vw 0;
	margin-bottom: 4vw;
	font-size: 4.8vw;
	font-weight: normal;
}
.sms-button {
	border: none;
	font-size: 4vw;
	color: #F4CD13;
}
.checked-style {
	font-size: 3.2vw;
	color: #999999;
}
</style>
